#pgOverlay{
    opacity: 0;
    z-index: -1;
    @include transition(all,0.4s);
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    background-color: white;
    background-image: url(../img/gears.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px 100px;
    &.active{
        opacity: 1;
        z-index: 1001;
    }
}
