.fr{
    &-box{
        &.fr{
            &-basic {
                .fr{
                    &-element{
                        min-height: 300px;
                    }
                }
            }
        }
    }
}
