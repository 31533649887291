.sortable{
    i{
        cursor:grabbing;
    }
}

.img{
    &_container{
        width: 100%;
        display: inline-block;
        margin: 0 0 15px;
        img{
            width:100%;
            height: auto;
        }
    }
}
#accordion {
    padding-bottom: 55px;
    .card{
        border-radius: 5px;
        margin: 17px 0 -38px;
        padding: 0 5px 5px;
        &-header{
            display: inline-block;
            margin: -12px -5px 5px;
            width: calc(100% + 10px);
            .mb-0 {
                background: #efefef none repeat scroll 0 0;
                border: 1px solid;
                border-radius: 5px;
                padding: 15px;
                a{
                    color: $text-color;
                    cursor: pointer;
                    font-weight: 700;
                }
            }
        }
        &-block {
            border: 1px solid #ccc;
            margin: -17px -5px 0;
            padding: 15px;
        }
        .btn{
            margin: 0 0 25px;
        }
    }
}

.media_item{
    a{
        height: 133px;
        display: inline-block;
        border: 2px solid white;
        @include transition(all, 0.4s);
        overflow: hidden;
        img{
            @include transition(all, 0.4s);
            object-fit: cover;
            height: 132px;
        }
        &:hover{
            border-color:#1abc9c;
            img{
                -webkit-filter: grayscale(1);
            	-webkit-filter: grayscale(100%);
            	filter: gray;
            	filter: grayscale(100%);
            }
        }
    }
}


.title_row form{
    display:inline-block;
}
.media_item{
    margin: 30px 0;
    text-align: center;
    img{
        width:auto;
        max-width: 100%;
        margin: 0 auto;
    }
}

.adm_section_img_container svg .opac{
    fill: #ddd;
}
